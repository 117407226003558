@font-face {
  font-family: 'DharmaGothic';
  src: url('fonts/DharmaGothicE_Regular_R.woff2') format('woff2'),
       url('fonts/DharmaGothicE_Regular_R.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RamaGothic';
  src: url('fonts/RamaGothicERegular.woff2') format('woff2'),
       url('fonts/RamaGothicERegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'VcrMono';
  src: url('fonts/VcrMono.woff2') format('woff2'),
       url('fonts/VcrMono.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('fonts/RobotoMono-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: light;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('fonts/RobotoMono-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'RobotoMono';
  src: url('fonts/RobotoMono-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ChivoMono';
  src: url('fonts/ChivoMono-Light.woff2') format('woff2');
  font-style: light;
}

@font-face {
  font-family: 'ChivoMono';
  src: url('fonts/ChivoMono-Medium.woff2') format('woff2');
  font-style: medium;
}

@font-face {
  font-family: 'ChivoMono';
  src: url('fonts/ChivoMono-LightItalic.woff2') format('woff2');
  font-style: italic;
}

@font-face {
  font-family: 'ChivoMono';
  src: url('fonts/ChivoMono-Thin.woff2') format('woff2');
  font-style: thin;
}

@font-face {
  font-family: 'BebasNeuePro';
  src: url('fonts/BebasNeuePro-Regular.woff') format('woff2'),
       url('fonts/BebasNeuePro-Regular.woff') format('woff');
}